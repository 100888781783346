body {
  background-color: #000000;
}

/* 100f0f */

body::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1; /* Ensure it stays behind the content */

  background-image: url(../public/OpVc.gif);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5; /* Adjust the opacity value as you need */
}

hr {
  animation: fade 2s infinite;
}

@keyframes fade {
  0% {
    border-color: #ff0303;
    opacity: 0;
  }
  50% {
    border-color: #ff0303;
    opacity: 0.8;
  }
  100% {
    border-color: #ff0303;
    opacity: 0;
  }
}

@keyframes roll {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0);
  }
}

.navbar {
  border-radius: 20px;
  backdrop-filter: blur(10px);
  opacity: 1;
  z-index: 20;
  position: fixed;
  margin: 1% auto;
  width: 70%;
}

.navbar-icon {
  font-size: 30px;
  margin: auto auto;
  background-color: #121212;
  padding: 10px 10px;
  border-radius: 10px;
  font-family: "Plaster", cursive;
}

.navbar-links {
  color: white;
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 30px;
}

.cursor {
  color: #d2001a;
  font-weight: bold;
  animation: blink 1.3s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.particles-canvas {
  /* pointer-events: none; */
  height: 100% !important;
  z-index: 0 !important;
  width: 100% !important;
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  overflow: hidden;
}

.particles-content {
  text-align: center;
  margin: 10% auto;
  position: relative;
  z-index: 1;
}

.particles-content h1 {
  color: white;
  /* opacity: 0.9; */
  font-family: "Bebas Neue", cursive;
  font-size: 100px;
  text-shadow: 8px 8px black;
  font-weight: bold;
  display: inline-block;
}

.particles-content span {
  opacity: 1;
  color: #d2001a;
  font-size: 180px;
  display: inline-block;
}

.particles-content h2 {
  color: gray;
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  opacity: 1;
  font-size: 50px;
}

.particles-content button {
  opacity: 1;
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 10px;
  font-family: "Bebas Neue", cursive;
  font-size: 30px;
  font-weight: bold;
}

.arrow {
  animation: vibration 2s infinite;
  color: #d2001a;
}

@keyframes vibration {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* ABOUT ME SECTION */

.aboutme-background {
  /* background-color: #100f0f; */
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(../public/code-clipart.PNG);
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: left;
}

.aboutme-background2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-image: url(../public/BA.png);
  background-size: 800px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 20px;
}

.aboutme-background hr {
  border: solid none none;
  width: 15%;
  margin: auto auto;
  border-width: 1px;
  display: block;
}

.aboutme-row {
  width: 100%;
  text-align: center;
  margin-top: 15%;
}

.aboutme-col {
  margin-top: 5%;
  text-align: center;
  color: white;
  opacity: 0.9;
}

.aboutme-col2 {
  opacity: 0.9;
  color: white;
  font-family: "Bebas Neue", cursive;
}

.aboutme-col2 h5 {
  font-size: 30px;
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
}

.aboutme-title {
  font-family: "Bebas Neue", cursive;
  font-size: 90px;
  text-shadow: 8px 8px black;
  font-weight: bold;
}

.aboutme-hobbies-title {
  text-align: center;
  font-size: 70px;
  font-family: "Bebas Neue", cursive;
  padding-top: 10rem;
  color: white;
}

.aboutme-typewritter h4 {
  font-size: 45px;
  color: white;
  font-family: "Bebas Neue", cursive;
  font-weight: bold;
  margin-top: 40px;
}

.aboutme-typewritter {
  font-size: 30px;
  font-family: "Rajdhani", sans-serif;
  color: #d2001a;
  font-weight: bold;
}

/* SKILLS SECTION */

.skills-title {
  margin-left: 20%;
  /* padding: 50px 10px; */
  font-family: "Bebas Neue", cursive;
  font-weight: bold;
  font-size: 160px;
}

.skills-col {
  margin: auto auto;
  text-align: center;
  font-family: "Rajdhani", sans-serif;
  color: white;
}

.skillsCol-content {
  font-size: 40px;
}

.tagcloud {
  color: white;
  font-size: 30px;
  margin: auto 5%;
  font-weight: bold;
}

/* PROJECTS SECTION  */

.projects-title {
  font-size: 290px;
  margin-left: 40%;
  font-weight: bold;
  font-family: "Bebas Neue", cursive;
  position: absolute;
  z-index: -1;
}

.projects-row {
  width: 100%;
  text-align: center;
  margin-top: 200px;
  z-index: 11;
}

.projects-row2 {
  width: 100%;
  text-align: center;
}

/* .col2 {
  padding-left: 170px;
  padding-right: 170px;
} */

.projects-img-gallery {
  width: 500px;
}

.modal-img {
  width: 80%;
}

.project-container {
  position: relative;
}

.project-container:hover .projects-img-gallery {
  transition: 0.5s ease;
  opacity: 1;
}

.project-container:hover .middle {
  transition: 0.5s ease;
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  border-radius: 50%;
  /* padding: 105px; */
  width: 300px;
  height: 300px;
  backdrop-filter: blur(10px) invert(80%);
  font-family: "Rajdhani", sans-serif;
}

.middle h1 {
  margin: 30% auto;
  font-size: 60px;
  font-weight: bold;
}

.modal-content {
  text-align: center;
}

.modal-content h5 {
  color: #d2001a;
}

.modal-content p {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (min-width: 1280px) and (min-height: 800px) and (-webkit-min-device-pixel-ratio: 2) {
  @keyframes vibration {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* ABOUT ME SECTION */

  .aboutme-background {
    /* background-color: #100f0f; */
    position: relative;
    height: 100%;
    width: 100%;
    background-image: url(../public/code-clipart.PNG);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: left;
  }

  .aboutme-background2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url(../public/BA.png);
    background-size: 650px;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 20px;
  }

  .aboutme-background hr {
    border: solid none none;
    width: 15%;
    margin: auto auto;
    border-width: 1px;
    display: block;
  }

  .aboutme-row {
    width: 100%;
    text-align: center;
    margin-top: 15%;
  }

  .aboutme-col {
    margin-top: 5%;
    text-align: center;
    color: white;
    opacity: 0.9;
  }

  .aboutme-col2 {
    opacity: 0.9;
    color: white;
    font-family: "Bebas Neue", cursive;
  }

  .aboutme-col2 h5 {
    font-size: 30px;
    font-family: "Rajdhani", sans-serif;
    font-weight: bold;
  }

  .aboutme-title {
    font-family: "Bebas Neue", cursive;
    font-size: 75px;
    text-shadow: 8px 8px black;
    font-weight: bold;
    margin-top: 10%;
  }

  .aboutme-hobbies-title {
    text-align: center;
    font-size: 70px;
    font-family: "Bebas Neue", cursive;

    color: white;
  }

  .aboutme-typewritter h4 {
    font-size: 45px;
    color: white;
    font-family: "Bebas Neue", cursive;
    font-weight: bold;
    margin-top: 40px;
  }

  .aboutme-typewritter {
    font-size: 30px;
    font-family: "Rajdhani", sans-serif;
    color: #d2001a;
    font-weight: bold;
  }

  /* SKILLS SECTION */

  .skills-title {
    margin-left: 10%;
    /* padding: 50px 10px; */
    font-family: "Bebas Neue", cursive;
    font-weight: bold;
    font-size: 140px;
  }

  .skills-col {
    margin: auto auto;
    text-align: center;
    font-family: "Rajdhani", sans-serif;

    color: white;
  }

  .skillsCol-content {
    font-size: 40px;
  }

  .tagcloud {
    color: white;
    font-size: 30px;
    margin: 0;
    /* margin: auto 25%; */
    font-weight: bold;
  }

  /* PROJECTS SECTION  */

  .projects-title {
    font-size: 290px;
    margin-left: 25%;
    font-weight: bold;
    font-family: "Bebas Neue", cursive;
    position: absolute;
    z-index: -1;
  }

  .projects-row {
    width: 100%;
    text-align: center;
    margin-top: 200px;
    z-index: 11;
  }

  .projects-row2 {
    width: 100%;
    text-align: center;
  }

  /* .col2 {
    padding-left: 170px;
    padding-right: 170px;
  } */

  .projects-img-gallery {
    width: 400px;
  }

  .modal-img {
    width: 80%;
  }

  .project-container {
    position: relative;
  }

  .project-container:hover .projects-img-gallery {
    transition: 0.5s ease;
    opacity: 1;
  }

  .project-container:hover .middle {
    transition: 0.5s ease;
    opacity: 1;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    border-radius: 50%;
    /* padding: 105px; */
    width: 200px;
    height: 200px;
    backdrop-filter: blur(10px) invert(80%);
    font-family: "Rajdhani", sans-serif;
  }

  .middle h1 {
    margin: 30% auto;
    font-size: 50px;
    font-weight: bold;
  }

  .modal-content {
    text-align: center;
  }

  .modal-content h5 {
    color: #d2001a;
  }

  .modal-content p {
    font-family: "Rajdhani", sans-serif;
    font-weight: bold;
    font-size: 20px;
  }
}

/* CONTACT SECTION  */

.contact-background {
  height: 100vh;
  width: 100%;
  background-color: #171717;
  position: relative;
  display: flex;
  background-image: url(../public/code-clipart2.PNG);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 700px;
}

.contact-form {
  text-align: center;
  margin: auto auto;
  padding-bottom: 10px;
  display: inline-block;
}

.contact-form p {
  color: gray;
}

.contact-form button {
  margin-left: 70%;
  background-color: transparent;
  border: solid #d2001a;
  color: white;
  padding: 7px 15px;
  font-weight: bold;
}

.thankyou-page {
  margin: 30% auto;
  font-size: 90px;
  font-family: "Bebas Neue", cursive;
  color: white;
}

.contact-title {
  text-align: center;
  padding-bottom: 0px;
  font-size: 85px;
  font-weight: bold;
  font-family: "Bebas Neue", cursive;
  color: white;
  display: inline-block;
}

.contact-title-dot {
  display: inline-block;
  font-size: 180px;
  color: #d2001a;
}

.contact-input {
  display: block;
  width: 30rem;
  height: 3rem;
  font-weight: bold;
  padding: 10px;
  background-color: #100f0f;
  border: none;
  color: white;
  outline: none;
}

.contact-textarea {
  display: block;
  width: 30rem;
  height: 7rem;
  font-weight: bold;
  padding: 10px;
  background-color: #100f0f;
  border: none;
  color: white;
  outline: none;
}

.footer-title {
  font-size: 20px;
  color: gray;
  margin-top: 40px;
}

.footer {
  padding-top: 30px;
  background-color: #100f0f;
  height: 200px;
  width: 100%;
  position: relative;
}

/* MOBILE LAYOUT */
@media (max-width: 992px) {
  /* body {
    background-color: #100f0f;
    background-image: url(../public/OpVc.gif);
    background-size: 100vh;
    background-repeat: no-repeat;
  } */

  body {
    background-color: #100f0f;
  }

  body::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1; /* Ensure it stays behind the content */

    background-image: url(../public/OpVc.gif);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity value as you need */
  }

  hr {
    animation: fade 2s infinite;
    width: 20%;
    margin: auto auto;
  }

  @keyframes fade {
    0% {
      border-color: #ff0303;
      opacity: 0;
    }
    50% {
      border-color: #ff0303;
      opacity: 0.8;
    }
    100% {
      border-color: #ff0303;
      opacity: 0;
    }
  }

  @keyframes roll {
    from {
      transform: translateX(-100px);
    }
    to {
      transform: translateX(0);
    }
  }

  .navbar {
    border-radius: 20px;
    backdrop-filter: blur(8px);
    opacity: 1;
    z-index: 20;
    /* border: solid 1px #100f0f; */
    position: fixed;
    margin: 2% auto;
    width: 90%;
  }

  .navbar-icon {
    font-size: 30px;
    margin: auto auto;
    background-color: #121212;
    padding: 10px 10px;
    border-radius: 10px;
    font-family: "Plaster", cursive;
  }

  .navbar-links {
    color: white;
    font-family: "Rajdhani", sans-serif;
    font-weight: bold;
    font-size: 25px;
  }

  .nav-toggle .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(210,0,26)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    border: none;
    opacity: 0.7;
  }

  .cursor {
    color: #d2001a;
    font-weight: bold;
    animation: blink 1.3s infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }

  .particles-canvas {
    /* pointer-events: none; */
    overflow: hidden;
    height: 100% !important;
    z-index: 0 !important;
    width: 100% !important;
    position: absolute;
    top: 0px !important;
    left: 0px !important;
  }

  .particles-content {
    text-align: center;
    margin: 40% auto;
  }

  .particles-content h1 {
    color: white;
    opacity: 0.9;
    font-family: "Bebas Neue", cursive;
    font-size: 50px;
    text-shadow: 8px 8px black;
    display: inline-block;
  }

  .particles-content span {
    opacity: 0.9;
    color: #ff0303;
    font-size: 110px;
    display: inline-block;
  }

  .particles-content h2 {
    color: gray;
    opacity: 0.9;
    font-size: 30px;
  }

  .arrow {
    animation: vibration 2s infinite;
    color: #d2001a;
  }

  .particles-content button {
    opacity: 0.9;
    background-color: transparent;
    border: none;
    color: white;
    padding: 10px 10px;
    font-weight: bold;
  }

  /* ABOUT ME SECTION */

  .aboutme-background {
    /* background-color: #100f0f; */
    position: relative;
    height: 100%;
    width: 100%;
    background-image: url(../public/code-clipart.PNG);
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: top;
  }

  .aboutme-background2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url(../public/BA.png);
    background-size: 400px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .aboutme-background hr {
    border: solid none none;
    width: 25%;
    margin: auto auto;
    border-width: 1px;
    display: block;
  }

  .aboutme-row {
    width: 100%;
    text-align: center;
    margin-top: 10%;
  }

  .aboutme-col {
    padding: 20px 40px;
    margin: auto auto;
    margin-left: 10px;
    text-align: center;
    color: white;
  }

  .aboutme-col2 {
    opacity: 0.9;
    margin-top: 300px;
    padding: 40px 40px;
    text-align: center;
    color: white;
  }

  .aboutme-col2 h5 {
    font-size: 21px;
    font-weight: normal;
  }

  .aboutme-title {
    font-size: 45px;
    font-weight: bold;
    text-shadow: 8px 8px black;
    margin-top: 30px;
    display: inline-block;
  }

  .aboutme-dot {
    opacity: 0.9;
    color: #ff0303;
    font-size: 120px;
    display: inline-block;
  }

  .aboutme-hobbies-title {
    text-align: center;
    font-size: 50px;
    color: white;
    margin-top: -10rem;
  }

  .aboutme-typewritter h4 {
    font-size: 35px;
    font-weight: bold;
    color: white;
    margin-top: 40px;
  }

  .aboutme-typewritter {
    margin-top: 70px;
    text-align: left;
    font-size: 25px;
    color: #d2001a;
    font-weight: bold;
  }

  /* SKILLS SECTION */

  .skills-title {
    margin-left: 5%;
    padding: 40px 10px;
    font-size: 70px;
    font-weight: bold;
  }

  .skills-col {
    margin: auto auto;
    padding: 30px 30px;
    text-align: center;
    color: white;
  }

  .skillsCol-content {
    font-size: 22px;
  }

  .tagcloud {
    color: white;
    font-size: 20px;
    margin: 40px auto;
  }

  /* PROJECTS SECTION  */

  .projects-title {
    font-size: 90px;
    margin-left: 35px;
    position: relative;
    z-index: -1;
  }

  .projects-row {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    z-index: 11;
    margin-left: 1px;
  }

  .projects-row2 {
    width: 100%;
    text-align: center;
    margin-left: 1px;
  }

  .col2 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .projects-img-gallery {
    width: 300px;
  }

  .modal-img {
    width: 90%;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    border-radius: 50%;
    /* padding: 105px; */
    width: 200px;
    height: 200px;
    backdrop-filter: blur(10px);
    font-family: "Rajdhani", sans-serif;
  }
  .middle h1 {
    margin: 30% auto;
    font-size: 40px;
    font-weight: bold;
  }

  /* CONTACT SECTION  */

  .contact-background {
    height: 100%;
    width: 100%;
    background-color: #171717;
    position: relative;
    display: flex;
    background-image: url(../public/code-clipart2.PNG);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 360px;
  }

  .contact-form {
    text-align: center;
    margin: auto auto;
    padding-bottom: 50px;
  }

  .contact-form p {
    color: gray;
    font-size: 15px;
  }

  .contact-form button {
    margin-left: 5%;
    background-color: transparent;
    border: solid #d2001a;
    color: white;
    padding: 7px 15px;
    font-weight: bold;
  }

  .thankyou-page {
    margin: 80% auto;
    font-size: 50px;
    font-family: "Bebas Neue", cursive;
    color: white;
  }

  .contact-title {
    text-align: center;
    padding-bottom: 20px;
    font-size: 60px;
    font-weight: bold;
    color: white;
    display: inline-block;
  }

  .contact-title-dot {
    display: inline-block;
    font-size: 110px;
    color: #d2001a;
  }

  .contact-input {
    display: block;
    width: 15rem;
    height: 3rem;
    font-weight: bold;
    padding: 10px;
    background-color: #100f0f;
    border: none;
    color: white;
    outline: none;
  }

  .contact-textarea {
    display: block;
    width: 15rem;
    height: 7rem;
    font-weight: bold;
    padding: 10px;
    background-color: #100f0f;
    border: none;
    color: white;
    outline: none;
  }

  .footer-title {
    font-size: 17px;
    color: gray;
    margin-top: 30px;
  }

  .footer {
    padding-top: 50px;
    background-color: #100f0f;
    height: 100vh;
    width: 100%;
  }
}
